import dynamic from 'next/dynamic'

import { RegisterModalAcceptProps } from '@features/authentication/components/RegisterModal/interface'

import { CategoryModalProps } from '@components/StoryCard/interface'
import {
  AddressInformationModalProps,
  ChangeEmailModalProps,
  ChangePasswordModalProps,
  ForgotPasswordModalProps,
} from '@features/profile/pages/SettingProfilePage/components/PersonalInformationForm/interface'
import { ListBlockedModalProps } from '@features/profile/pages/SettingProfilePage/components/SettingPrivacy/components/ListBlockedModal/interface'
import { ConfirmUnblockModalProps } from '@features/profile/pages/SettingProfilePage/components/SettingPrivacy/components/ConfirmUnblockModal/interface'
import { WithViewAllSponsorsModalProps } from '@features/story/pages/StoryPage/components/PayRank/components/ViewAllSponsorsModal/interface'
import { SupportCharacterModalAcceptProps } from '@components/SupportCharacterModal/interface'
import { WithAddPenNameModalProps } from '@features/myWriting/pages/MyWritingPage/components/SettingPenName/components/AddPenNameModal/interface'
import { EditPenNameModalProps } from '@features/myWriting/pages/MyWritingPage/components/SettingPenName/components/EditPenNameModal/interface'
import { DeletePenNameModalProps } from '@features/myWriting/pages/MyWritingPage/components/SettingPenName/components/DeletePenNameModal/interface'
import { InProgressModalProps } from '@features/profile/pages/SettingProfilePage/components/InProgressModal/interface'
import { WriterTermsOfServiceModalProps } from '@features/myWriting/pages/MyWritingPage/components/UpgradeToWriter/components/WriterTermsOfServiceModal'
import { WithManageCollectionListModalProps } from '@features/myReading/components/ManageCollectionListModal/interface'
import { WithConfirmModalProps } from '@components/ConfirmModal/interface'
import { SetCoinModalProps } from '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/SetCoinModal/interface'
import { ReportModalProps } from '@components/ReportModal/interface'
import { ConfirmLeavePageModalProps } from '@components/ConfirmLeavePageModal/interface'
import { WithChapterSettingPublishTimeProps } from '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/ChapterSettingPublishTime/interface'
import { WithChapterListSettingPublishTimeProps } from '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/ChapterListSettingPublishTime/interface'
import { WithSetPromotionModalProps } from '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/SetPromotionModal/interface'
import { WithBuyChapterProps } from '@features/story/components/BuyChapter/interface'
import { WithNotEnoughCoinProps } from '@features/story/components/NotEnoughCoin/interface'
import { WithNotEnoughReadFreeQuotaProps } from '@features/story/components/NotEnoughReadFreeQuota/interface'
import { WithBuyChapterListProps } from '@features/story/components/BuyChapterList/interface'
import { WithManageFollowingModalProps } from '@features/myReading/pages/MyReadingPage/components/MyFollowing/components/ManageFollowingModal/interface'
import { BlockedUserModalProps } from '@components/BlockedUserModal/interface'
import { DolfinPaymentAcceptProps } from '@features/myCoin/pages/CoinPaymentPage/components/DolfinPayment/interface'
import { AlertModalProps } from '@components/AlertModal/interface'
import { LoginAndVerifyAgeModalProps } from '@features/story/components/LoginAndVerifyAgeModal/interface'
import { WaitVerifyAgeModalProps } from '@features/story/components/WaitVerifyAgeModal/interface'
import { VerifyAgeModalProps } from '@features/story/components/VerifyAgeModal/interface'
import { ReaderAgeLimitModalProps } from '@features/story/components/ReaderAgeLimitModal/interface'
import { ConfirmChallengeCommentProps } from '@components/Comment/components/ConfirmChallengeComment/interface'
import { PublishHandlerModalProps } from '@features/myWriting/pages/StoryDetailPage/components/Character/components/PublishHandlerModal/interface'
import { PublishHandlerAllModalProps } from '@features/myWriting/pages/StoryDetailPage/components/Character/components/PublishHandlerAllModal/interface'
import { WithDonationAllHandlerModalProps } from '@features/myWriting/pages/StoryDetailPage/components/Character/components/DonationAllHandlerModal/interface'
import { ScheduleHandlerModalProps } from '@features/myWriting/pages/StoryDetailPage/components/Character/components/ScheduleHandlerModal/interface'
import { ScheduleAllHandlerModalProps } from '@features/myWriting/pages/StoryDetailPage/components/Character/components/ScheduleAllHandlerModal/interface'
import { ReadEbookOnAppProps } from '@features/myReading/pages/MyReadingPage/components/BookShelves/components/ReadEbookOnApp/interface'
import { WithConfirmEbookModalProps } from '@features/myWriting/components/SettingEbookForm/components/ConfirmEbookModal/interface'
import { WithBuyAllPromotionProps } from '@features/story/components/BuyAllPromotion/interface'
import { WithEbookSettingPublishTimeProps } from '@features/myWriting/pages/MyWritingPage/components/MyEbookDetail/components/EbookSettingPublishTime/interface'
import { WithEbookListSettingPublishTimeProps } from '@features/myWriting/pages/MyWritingPage/components/MyEbookDetail/components/EbookListSettingPublishTime/interface'
import { WithEbookSettingPromotionProps } from '@features/myWriting/pages/MyWritingPage/components/MyEbookDetail/components/EbookSettingPromotion/interface'
import { WithPrivacyPolicyModalProps } from '@features/authentication/components/PrivacyPolicyModal/interface'
import { WithBuyEbookModalProps } from '@features/ebook/pages/EbookPage/components/BuyEbookModal/interface'
import { TrueMoneyWalletPaymentAcceptProps } from '@features/myCoin/pages/CoinPaymentPage/components/TrueMoneyWalletPayment/interface'
import { WithFootnoteModalProps } from '@components/FootnoteModal/interface'

const LoadingModal = dynamic<unknown>(() =>
  import('@components/LoadingModal/LoadingModal').then(v => v.LoadingModal)
)
const LoginModal = dynamic<unknown>(() =>
  import('@features/authentication/components/LoginModal').then(
    v => v.LoginModal
  )
)
const ForgotPasswordModal = dynamic<unknown>(() =>
  import('@features/authentication/components/ForgotPasswordModal').then(
    v => v.ForgotPasswordModal
  )
)
const ResetPasswordSuccessModal = dynamic<unknown>(() =>
  import(
    '@features/authentication/components/SuccesModal/ResetPasswordSuccessModal'
  ).then(v => v.ResetPasswordSuccessModal)
)
const RegisterModal = dynamic<RegisterModalAcceptProps>(() =>
  import('@features/authentication/components/RegisterModal').then(
    v => v.RegisterModal
  )
)
const SelectWritingTypeModal = dynamic<unknown>(() =>
  import('@features/myWriting/components/SelectWritingTypeModal').then(
    v => v.SelectWritingTypeModal
  )
)
const CategoryModal = dynamic<CategoryModalProps>(
  import('@components/StoryCard/CategoryModal').then(v => v.CategoryModal)
)
const SetCoinModal = dynamic<SetCoinModalProps>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/SetCoinModal'
  ).then(v => v.SetCoinModal)
)
const SetPromotionModal = dynamic<WithSetPromotionModalProps>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/SetPromotionModal'
  ).then(v => v.SetPromotionModal)
)
const SetPublishTimeModal = dynamic<unknown>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/SetPublishTimeModal'
  ).then(v => v.SetPublishTimeModal)
)
const ChangePasswordModal = dynamic<ChangePasswordModalProps>(
  import(
    '@features/profile/pages/SettingProfilePage/components/PersonalInformationForm/ChangePasswordModal'
  ).then(v => v.ChangePasswordModal)
)
const PersonalInformationForgotPasswordModal =
  dynamic<ForgotPasswordModalProps>(() =>
    import(
      '@features/profile/pages/SettingProfilePage/components/PersonalInformationForm/ForgotPasswordModal'
    ).then(v => v.ForgotPasswordModal)
  )
const AddressInformationModal = dynamic<AddressInformationModalProps>(() =>
  import(
    '@features/profile/pages/SettingProfilePage/components/PersonalInformationForm/AddressInformationModal'
  ).then(v => v.AddressInformationModal)
)
const PublishHandlerModal = dynamic<PublishHandlerModalProps>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/Character/components/PublishHandlerModal'
  ).then(v => v.PublishHandlerModal)
)
const PublishHandlerAllModal = dynamic<PublishHandlerAllModalProps>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/Character/components/PublishHandlerAllModal'
  ).then(v => v.PublishHandlerAllModal)
)
const DonationAllHandlerModal = dynamic<WithDonationAllHandlerModalProps>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/Character/components/DonationAllHandlerModal'
  ).then(v => v.DonationAllHandlerModal)
)
const ScheduleHandlerModal = dynamic<ScheduleHandlerModalProps>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/Character/components/ScheduleHandlerModal'
  ).then(v => v.ScheduleHandlerModal)
)
const ScheduleAllHandlerModal = dynamic<ScheduleAllHandlerModalProps>(() =>
  import(
    '@features/myWriting/pages/StoryDetailPage/components/Character/components/ScheduleAllHandlerModal'
  ).then(v => v.ScheduleAllHandlerModal)
)
const ListBlockedModal = dynamic<ListBlockedModalProps>(() =>
  import(
    '@features/profile/pages/SettingProfilePage/components/SettingPrivacy/components/ListBlockedModal'
  ).then(v => v.ListBlockedModal)
)
const ConfirmUnblockModal = dynamic<ConfirmUnblockModalProps>(() =>
  import(
    '@features/profile/pages/SettingProfilePage/components/SettingPrivacy/components/ConfirmUnblockModal'
  ).then(v => v.ConfirmUnblockModal)
)
const ViewAllSponsorsModal = dynamic<WithViewAllSponsorsModalProps>(() =>
  import(
    '@features/story/pages/StoryPage/components/PayRank/components/ViewAllSponsorsModal'
  ).then(v => v.ViewAllSponsorsModal)
)
const SupportCharacterModal = dynamic<SupportCharacterModalAcceptProps>(() =>
  import('@components/SupportCharacterModal').then(v => v.SupportCharacterModal)
)
const AddPenNameModal = dynamic<WithAddPenNameModalProps>(() =>
  import(
    '@features/myWriting/pages/MyWritingPage/components/SettingPenName/components/AddPenNameModal'
  ).then(v => v.AddPenNameModal)
)
const EditPenNameModal = dynamic<EditPenNameModalProps>(() =>
  import(
    '@features/myWriting/pages/MyWritingPage/components/SettingPenName/components/EditPenNameModal'
  ).then(v => v.EditPenNameModal)
)
const DeletePenNameModal = dynamic<DeletePenNameModalProps>(() =>
  import(
    '@features/myWriting/pages/MyWritingPage/components/SettingPenName/components/DeletePenNameModal'
  ).then(v => v.DeletePenNameModal)
)
const ErrorDeletePenNameModal = dynamic<unknown>(() =>
  import(
    '@features/myWriting/pages/MyWritingPage/components/SettingPenName/components/ErrorDeletePenNameModal'
  ).then(v => v.ErrorDeletePenNameModal)
)
const InProgressModal = dynamic<InProgressModalProps>(() =>
  import(
    '@features/profile/pages/SettingProfilePage/components/InProgressModal/InProgressModal'
  ).then(v => v.InProgressModal)
)
const WriterTermsOfServiceModal = dynamic<WriterTermsOfServiceModalProps>(() =>
  import(
    '@features/myWriting/pages/MyWritingPage/components/UpgradeToWriter/components/WriterTermsOfServiceModal'
  ).then(v => v.WriterTermsOfServiceModal)
)
const ManageCollectionListModal = dynamic<WithManageCollectionListModalProps>(
  () =>
    import('@features/myReading/components/ManageCollectionListModal').then(
      v => v.ManageCollectionListModal
    )
)
const ManageFollowingModal = dynamic<WithManageFollowingModalProps>(() =>
  import(
    '@features/myReading/pages/MyReadingPage/components/MyFollowing/components/ManageFollowingModal'
  ).then(v => v.ManageFollowingModal)
)
const ConfirmModal = dynamic<WithConfirmModalProps>(() =>
  import('@components/ConfirmModal').then(v => v.ConfirmModal)
)
const FootnoteModal = dynamic<WithFootnoteModalProps>(() =>
  import('@components/FootnoteModal').then(v => v.FootnoteModal)
)
const AlertModal = dynamic<AlertModalProps>(() =>
  import('@components/AlertModal').then(v => v.AlertModal)
)
const ReadEbookOnApp = dynamic<ReadEbookOnAppProps>(() =>
  import(
    '@features/myReading/pages/MyReadingPage/components/BookShelves/components/ReadEbookOnApp'
  ).then(v => v.ReadEbookOnApp)
)
const ConfirmLeavePageModal = dynamic<ConfirmLeavePageModalProps>(() =>
  import('@components/ConfirmLeavePageModal').then(v => v.ConfirmLeavePageModal)
)
const ReportModal = dynamic<ReportModalProps>(() =>
  import('@components/ReportModal/ReportModal').then(v => v.ReportModal)
)
const ChapterSettingPublishTime = dynamic<WithChapterSettingPublishTimeProps>(
  () =>
    import(
      '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/ChapterSettingPublishTime'
    ).then(v => v.ChapterSettingPublishTime)
)
const ChapterListSettingPublishTime =
  dynamic<WithChapterListSettingPublishTimeProps>(() =>
    import(
      '@features/myWriting/pages/StoryDetailPage/components/ManageChapters/components/ChapterListSettingPublishTime'
    ).then(v => v.ChapterListSettingPublishTime)
  )
const BuyChapter = dynamic<WithBuyChapterProps>(() =>
  import('@features/story/components/BuyChapter').then(v => v.BuyChapter)
)
const NotEnoughCoin = dynamic<WithNotEnoughCoinProps>(() =>
  import('@features/story/components/NotEnoughCoin').then(v => v.NotEnoughCoin)
)
const NotEnoughReadFreeQuota = dynamic<WithNotEnoughReadFreeQuotaProps>(() =>
  import('@features/story/components/NotEnoughReadFreeQuota').then(
    v => v.NotEnoughReadFreeQuota
  )
)
const BuyChapterList = dynamic<WithBuyChapterListProps>(() =>
  import('@features/story/components/BuyChapterList').then(
    v => v.BuyChapterList
  )
)
const StoryRestrictModal = dynamic<unknown>(() =>
  import('@features/story/components/StoryRestrictModal').then(
    v => v.StoryRestrictModal
  )
)
const BlockedUserModal = dynamic<BlockedUserModalProps>(() =>
  import('@components/BlockedUserModal').then(v => v.BlockedUserModal)
)
const DolfinPayment = dynamic<DolfinPaymentAcceptProps>(() =>
  import(
    '@features/myCoin/pages/CoinPaymentPage/components/DolfinPayment'
  ).then(v => v.DolfinPayment)
)
const ConsentModal = dynamic<unknown>(() =>
  import('@features/authentication/components/ConsentModal').then(
    v => v.ConsentModal
  )
)
const PrivacyPolicyModal = dynamic<WithPrivacyPolicyModalProps>(() =>
  import('@features/authentication/components/PrivacyPolicyModal').then(
    v => v.PrivacyPolicyModal
  )
)

const ReaderAgeLimitModal = dynamic<ReaderAgeLimitModalProps>(() =>
  import('@features/story/components/ReaderAgeLimitModal').then(
    v => v.ReaderAgeLimitModal
  )
)
const LoginAndVerifyAgeModal = dynamic<LoginAndVerifyAgeModalProps>(() =>
  import('@features/story/components/LoginAndVerifyAgeModal').then(
    v => v.LoginAndVerifyAgeModal
  )
)
const VerifyAgeModal = dynamic<VerifyAgeModalProps>(() =>
  import('@features/story/components/VerifyAgeModal').then(
    v => v.VerifyAgeModal
  )
)
const WaitVerifyAgeModal = dynamic<WaitVerifyAgeModalProps>(() =>
  import('@features/story/components/WaitVerifyAgeModal').then(
    v => v.WaitVerifyAgeModal
  )
)
const ConfirmChallengeComment = dynamic<ConfirmChallengeCommentProps>(() =>
  import('@components/Comment/components/ConfirmChallengeComment').then(
    v => v.ConfirmChallengeComment
  )
)
const ConfirmEbookModal = dynamic<WithConfirmEbookModalProps>(() =>
  import(
    '@features/myWriting/components/SettingEbookForm/components/ConfirmEbookModal'
  ).then(v => v.ConfirmEbookModal)
)
const BuyAllPromotion = dynamic<WithBuyAllPromotionProps>(() =>
  import('@features/story/components/BuyAllPromotion').then(
    v => v.BuyAllPromotion
  )
)
const EbookSettingPublishTime = dynamic<WithEbookSettingPublishTimeProps>(() =>
  import(
    '@features/myWriting/pages/MyWritingPage/components/MyEbookDetail/components/EbookSettingPublishTime'
  ).then(v => v.EbookSettingPublishTime)
)
const EbookListSettingPublishTime =
  dynamic<WithEbookListSettingPublishTimeProps>(() =>
    import(
      '@features/myWriting/pages/MyWritingPage/components/MyEbookDetail/components/EbookListSettingPublishTime'
    ).then(v => v.EbookListSettingPublishTime)
  )
const EbookSettingPromotion = dynamic<WithEbookSettingPromotionProps>(() =>
  import(
    '@features/myWriting/pages/MyWritingPage/components/MyEbookDetail/components/EbookSettingPromotion'
  ).then(v => v.EbookSettingPromotion)
)

const ChangeUsernameModal = dynamic<unknown>(() =>
  import(
    '@features/profile/pages/SettingProfilePage/components/PersonalInformationForm/ChangeUsernameModal'
  ).then(v => v.ChangeUsernameModal)
)

const ChangeEmailModal = dynamic<ChangeEmailModalProps>(() =>
  import(
    '@features/profile/pages/SettingProfilePage/components/PersonalInformationForm/ChangeEmailModal'
  ).then(v => v.ChangeEmailModal)
)

const WaitDeleteAccountModal = dynamic<unknown>(() =>
  import('@features/profile/components/WaitDeleteAccountModal').then(
    v => v.WaitDeleteAccountModal
  )
)

const BuyEbookModal = dynamic<WithBuyEbookModalProps>(() =>
  import('@features/ebook/pages/EbookPage/components/BuyEbookModal').then(
    v => v.BuyEbookModal
  )
)

const TrueMoneyWalletPayment = dynamic<TrueMoneyWalletPaymentAcceptProps>(() =>
  import(
    '@features/myCoin/pages/CoinPaymentPage/components/TrueMoneyWalletPayment'
  ).then(v => v.TrueMoneyWalletPayment)
)

export const ModalList = {
  // DESC: Setup key and component
  footnote: (props: WithFootnoteModalProps) => <FootnoteModal {...props} />,
  loading: () => <LoadingModal />,
  login: () => <LoginModal />,
  forgotPassword: () => <ForgotPasswordModal />,
  resetPasswordSuccess: () => <ResetPasswordSuccessModal />,
  register: (props: RegisterModalAcceptProps) => <RegisterModal {...props} />,
  createStory: () => <SelectWritingTypeModal />,
  categoryModal: (props: CategoryModalProps) => <CategoryModal {...props} />,
  setCoin: (props: SetCoinModalProps) => <SetCoinModal {...props} />,
  setPromotionModal: (props: WithSetPromotionModalProps) => (
    <SetPromotionModal {...props} />
  ),
  setPublishTime: () => <SetPublishTimeModal />,
  personalInformationChangePassword: (props: ChangePasswordModalProps) => (
    <ChangePasswordModal {...props} />
  ),
  personalInformationForgotPassword: (props: ForgotPasswordModalProps) => (
    <PersonalInformationForgotPasswordModal {...props} />
  ),
  personalInformationAddress: (props: AddressInformationModalProps) => (
    <AddressInformationModal {...props} />
  ),
  publishCharacter: (props: PublishHandlerModalProps) => (
    <PublishHandlerModal {...props} />
  ),
  publishAllCharacter: (props: PublishHandlerAllModalProps) => (
    <PublishHandlerAllModal {...props} />
  ),
  setDonationCharacter: (props: WithDonationAllHandlerModalProps) => (
    <DonationAllHandlerModal {...props} />
  ),
  setScheduleCharacter: (props: ScheduleHandlerModalProps) => (
    <ScheduleHandlerModal {...props} />
  ),
  setScheduleAllCharacter: (props: ScheduleAllHandlerModalProps) => (
    <ScheduleAllHandlerModal {...props} />
  ),
  profileListBlocked: (props: ListBlockedModalProps) => (
    <ListBlockedModal {...props} />
  ),
  profileConfirmUnblock: (props: ConfirmUnblockModalProps) => (
    <ConfirmUnblockModal {...props} />
  ),
  viewAllSponsors: (props: WithViewAllSponsorsModalProps) => (
    <ViewAllSponsorsModal {...props} />
  ),
  supportCharacter: (props: SupportCharacterModalAcceptProps) => (
    <SupportCharacterModal {...props} />
  ),
  addPenName: (props: WithAddPenNameModalProps) => (
    <AddPenNameModal {...props} />
  ),
  editPenName: (props: EditPenNameModalProps) => (
    <EditPenNameModal {...props} />
  ),
  deletePenName: (props: DeletePenNameModalProps) => (
    <DeletePenNameModal {...props} />
  ),
  errorPenName: () => <ErrorDeletePenNameModal />,
  inProgress: (props: InProgressModalProps) => <InProgressModal {...props} />,
  writerTermsOfService: (props: WriterTermsOfServiceModalProps) => (
    <WriterTermsOfServiceModal {...props} />
  ),
  manageCollectionList: (props: WithManageCollectionListModalProps) => (
    <ManageCollectionListModal {...props} />
  ),
  manageFollowing: (props: WithManageFollowingModalProps) => (
    <ManageFollowingModal {...props} />
  ),
  confirm: (props: WithConfirmModalProps) => <ConfirmModal {...props} />,
  alert: (props: AlertModalProps) => <AlertModal {...props} />,
  readEbookOnApp: (props: ReadEbookOnAppProps) => <ReadEbookOnApp {...props} />,
  confirmLeavePage: (props: ConfirmLeavePageModalProps) => (
    <ConfirmLeavePageModal {...props} />
  ),
  reportModal: (props: ReportModalProps) => <ReportModal {...props} />,
  chapterSettingPublishTime: (props: WithChapterSettingPublishTimeProps) => (
    <ChapterSettingPublishTime {...props} />
  ),
  chapterListSettingPublishTime: (
    props: WithChapterListSettingPublishTimeProps
  ) => <ChapterListSettingPublishTime {...props} />,
  buyChapter: (props: WithBuyChapterProps) => <BuyChapter {...props} />,
  notEnoughCoin: (props: WithNotEnoughCoinProps) => (
    <NotEnoughCoin {...props} />
  ),
  notEnoughReadFreeQuota: (props: WithNotEnoughReadFreeQuotaProps) => (
    <NotEnoughReadFreeQuota {...props} />
  ),
  buyChapterList: (props: WithBuyChapterListProps) => (
    <BuyChapterList {...props} />
  ),
  restrictModal: () => <StoryRestrictModal />,
  blockedUser: (props: BlockedUserModalProps) => (
    <BlockedUserModal {...props} />
  ),
  dolfinPayment: (props: DolfinPaymentAcceptProps) => (
    <DolfinPayment {...props} />
  ),
  consent: () => <ConsentModal />,
  privacyPolicy: (props: WithPrivacyPolicyModalProps) => (
    <PrivacyPolicyModal {...props} />
  ),
  readerAgeLimit: (props: ReaderAgeLimitModalProps) => (
    <ReaderAgeLimitModal {...props} />
  ),
  loginAndVerifyAge: (props: LoginAndVerifyAgeModalProps) => (
    <LoginAndVerifyAgeModal {...props} />
  ),
  verifyAge: (props: VerifyAgeModalProps) => <VerifyAgeModal {...props} />,
  waitVerifyAge: (props: WaitVerifyAgeModalProps) => (
    <WaitVerifyAgeModal {...props} />
  ),
  confirmChallengeComment: (props: ConfirmChallengeCommentProps) => (
    <ConfirmChallengeComment {...props} />
  ),
  confirmEbookModal: (props: WithConfirmEbookModalProps) => (
    <ConfirmEbookModal {...props} />
  ),
  buyAllPromotion: (props: WithBuyAllPromotionProps) => (
    <BuyAllPromotion {...props} />
  ),
  ebookSettingPublishTime: (props: WithEbookSettingPublishTimeProps) => (
    <EbookSettingPublishTime {...props} />
  ),
  ebookListSettingPublishTime: (
    props: WithEbookListSettingPublishTimeProps
  ) => <EbookListSettingPublishTime {...props} />,
  ebookSettingPromotion: (props: WithEbookSettingPromotionProps) => (
    <EbookSettingPromotion {...props} />
  ),
  personalInformationChangeUsername: () => <ChangeUsernameModal />,
  changeEmail: (props: ChangeEmailModalProps) => (
    <ChangeEmailModal {...props} />
  ),
  waitDeleteAccount: () => <WaitDeleteAccountModal />,
  buyEbookModal: (props: WithBuyEbookModalProps) => (
    <BuyEbookModal {...props} />
  ),
  trueMoneyWalletPayment: (props: TrueMoneyWalletPaymentAcceptProps) => (
    <TrueMoneyWalletPayment {...props} />
  ),
}
