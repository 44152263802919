export const API_URL = process.env.NEXT_PUBLIC_API_URL || ''
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || ''
export const KPAYMENT_PUBLUC_KEY =
  process.env.NEXT_PUBLIC_KPAYMENT_PUBLIC_KEY || ''
export const KPAYMENT_DEV_SCRIPT =
  process.env.NEXT_PUBLIC_KPAYMENT_DEV_SCRIPT || ''
export const KPAYMENT_MID = process.env.NEXT_PUBLIC_KPAYMENT_MID || ''
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || ''
export const LINE_ID = process.env.NEXT_PUBLIC_LINE_ID || ''
export const LINE_SECRET = process.env.NEXT_PUBLIC_LINE_SECRET || ''
export const LINE_AUTH_URL = process.env.NEXT_PUBLIC_LINE_AUTH_URL || ''
export const LINE_AUTH_API_URL = process.env.NEXT_PUBLIC_LINE_AUTH_API_URL || ''
export const NAIIN_AUTH_URL = process.env.NEXT_PUBLIC_NAIIN_AUTH_URL || ''
export const FACEBOOK_ID = process.env.NEXT_PUBLIC_FACEBOOK_ID || ''
export const S3_URL = process.env.NEXT_PUBLIC_S3_URL || ''
export const NAIIN_APP_CODE = process.env.NEXT_PUBLIC_NAIIN_APP_CODE || ''
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || ''
export const FACEBOOK_PIXEL_ADD_COIN =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ADD_COIN
export const FACEBOOK_PIXEL_BUY_CHAPTER =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_BUY_CHAPTER
export const FACEBOOK_PIXEL_DONATE_CHARACTER =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_DONATE_CHARACTER
export const FACEBOOK_PIXEL_REGISTER =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_REGISTER
export const FACEBOOK_PIXEL_BUY_CHAPTER_LIST =
  process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_BUY_CHAPTER_LIST
export const TIKTOK_ID = process.env.NEXT_PUBLIC_TIKTOK_ID || ''  
export const MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || ''
export const BECOOKIES_ID = process.env.NEXT_PUBLIC_BECOOKIES_ID || ''
export const REGISTER_CONVERSION =
  process.env.NEXT_PUBLIC_REGISTER_CONVERSION || ''
export const ADD_COIN_CONVERSION =
  process.env.NEXT_PUBLIC_ADD_COIN_CONVERSION || ''
export const BUY_CHAPTER_CONVERSION =
  process.env.NEXT_PUBLIC_BUY_CHAPTER_CONVERSION || ''
export const BUY_CHAPTER_LIST_CONVERSION =
  process.env.NEXT_PUBLIC_BUY_CHAPTER_LIST_CONVERSION || ''
export const DONATE_CHARACTER_CONVERSION =
  process.env.NEXT_PUBLIC_DONATE_CHARACTER_CONVERSION || ''
export const SHARE_FACEBOOK_CONVERSION =
  process.env.NEXT_PUBLIC_SHARE_FACEBOOK_CONVERSION || ''
export const OLD_S3_URL = process.env.NEXT_PUBLIC_OLD_S3_URL || ''
export const NEW_S3_URL = process.env.NEXT_PUBLIC_NEW_S3_URL || ''
export const SHOW_EBOOK_FEATURE =
  process.env.NEXT_PUBLIC_SHOW_EBOOK_FEATURE === 'true'
