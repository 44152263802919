import { MEASUREMENT_ID } from '@configs/config'

export enum ActionEnum {
  CONVERSION = 'conversion',
}

export function event(
  action: ActionEnum,
  params: {
    send_to: string
    value?: number
    currency?: string
    transaction_id?: string
  }
) {
  window.gtag('event', action, params)
}

export const pageView = () => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', MEASUREMENT_ID)
  }
}
