import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { pageView } from '@lib/gtag'

export function useGoogleAnalytics() {
  const router = useRouter()

  function handleRouteChange() {
    pageView()
  }

  useEffect(() => {
    pageView()

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
